<template>
  <b-card no-body>
    <b-card-header>
      <b-row
        align-v="center"
        class="w-100 m-0"
      >
        <b-col>
          <h4>
            {{ `ยอดที่ยังไม่สรุป ${total} รายการ` }}
            <b-button
              variant="flat-primary"
              class="btn-icon"
              size="sm"
              @click="fetchData"
            >
              <feather-icon
                icon="RefreshCcwIcon" 
                size="18"
              />
            </b-button>
          </h4>
        </b-col>
        <b-col
          cols="3"
          class="pr-0"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`ชื่อตัวแทน`"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div />
    </b-card-header>

    <hr class="m-0">
    <b-table
      ref="billSelectableTable"
      :items="dataList"
      :fields="fields"
      :busy="isFetching"
      responsive
      show-empty
      hover
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
    >
      <template #head(selected)="">
        <b-form-checkbox v-model="isSelectAll" />
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>

      <template #cell(from)="data">
        {{ data.value | date }}
        -
        {{ data.item.to | date }}
      </template>
      <template #cell(totalFee)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(commissionRate)="data">
        {{ data.value }}%
      </template>
      <template #cell(totalAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(agentId)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="printReport(data.item)"
        >
          สรุปยอดบิล
        </b-button>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #empty="">
        <div class="my-5">
          <p class="text-center text-muted">
            ไม่มียอดบิล
          </p>
        </div>
      </template>
    </b-table>

    <AddInvoiceModal
      :bill="selectedBill"
      @change-menu="onChangeMenu"
    />
    <!-- pagination -->
    <b-card-footer>
      <b-row class="">
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => (currentPage = val)"
          />
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'BillsList', 
  components: {
    AddInvoiceModal: () => import('./AddInvoiceModal.vue'),
  },
  data() {
    return {
      isSelectAll: false,

      currentPage: 0,
      selectedLimit: 20,

      search: '', 
      onSearchTimeout: null,

      fields: [
        'selected',
        {
          key: 'agentName',
          label: 'ตัวแทน',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'from',
          label: 'รอบปัจจุบัน',
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'totalFee',
          label: 'ค่าธรรมเนียม (ยังไม่สรุป)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'commissionRate',
          label: '% ส่วนแบ่ง',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'totalAmount',
          label: 'ยอดที่ต้องเก็บ (ยังไม่สรุป)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'agentId',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],

      selectedBill: null,
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.payment.isGettingBills,
    }),
    ...mapGetters(['bills']),
    dataList() {
      console.log(this.bills)
      return this.bills.data
    },
    pagination() {
      const { totalItems, perPage } = this.bills;
      return {
        itemsPerPage: perPage || this.selectedLimit,
        totalItems,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    isSelectAll(val) {
      if(val){
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
  },
  methods: {
    ...mapActions(['getBills', 'resetInvoiceStates']),
    fetchData() {
      this.getBills({
        limit: this.selectedLimit,
        skip: this.currentPage,
        search: this.search,
      });
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onRowSelected() {
    },
    selectAllRows() {
      this.$refs.billSelectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.billSelectableTable.clearSelected()
    },
    printReport(val) {
      this.selectedBill = val
      this.resetInvoiceStates();
      this.$bvModal.show('add-invoice-modal')
    },
    onChangeMenu(menu) {
      this.$emit('change-menu', menu)
    }
  },
}
</script>
 